import React from 'react'
import mapImg from '../assets/destinations/map.svg'

import style from './yacht-destinations.module.css'

// TODO: Fetch from CMS
// const DESTINATIONS_DATA = [
//   {
//     heading: 'Morocco',
//     subheading: '',
//     body: ''
//   }
// ]

const DestinationsPage = props => {
  return (
    <>
      <header className={style.header}>
        <img src={mapImg} alt='world map' />
        <h1>DESTINED FOR YOU</h1>
        <h2 className='p1'>Coming Soon...</h2>
      </header>

      {/* <InfoSection
        b={true}
        heading="INDUSTRY VETERANS"
        copy="We specialise in providing charters on both Sail and Motor yachts globally. With our industry expertise we can match you to your dream charter vessel, be it Sail, Motor, Classic or Modern. All packages are tailor made so you can be assured you are getting exactly what you’re looking for."
        image={veteransImg}
      /> */}

      <section className={style.faq} />
    </>
  )
}

export default DestinationsPage
